import { DaysBetweenCourses } from "@/pages/awareness/setup/DaysBetweenCourses";
import React from "react";
import { Reminders } from "@/pages/awareness/setup/Reminders";
import { Stack } from "@mui/material";
import { SendOnboardings } from "@/pages/awareness/setup/SendOnboardings";
import FreechatPreferences from "./FreechatPreferences";

export function Preferences({
  days_between_courses,
}: {
  days_between_courses: number;
}) {
  return (
    <Stack spacing={3}>
      <SendOnboardings />
      <Reminders />
      <DaysBetweenCourses days_between_courses={days_between_courses} />
      <FreechatPreferences />
    </Stack>
  );
}
