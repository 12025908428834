import { createSlice } from "@reduxjs/toolkit";
import { MailHost, SetupChecklistSliceType } from "./SetupChecklistTypes";
import { RootState } from "@/store";
import { SetupStepper } from "./SetupStepperService";
import { SetupStep } from "@/components/stepper/Stepper";

const initialState: SetupChecklistSliceType = {
  // isMantraStaff: false,
  mailHost: MailHost.OFFICE,
  hasMailPermissions: false,
  // provisioning: "OFFICE",
  hasProvisioningPermissions: false,
  hasAtLeastOneNonAdminUser: false,
  deliveryTests: {},
  companyScan: {
    hasKeyPeople: false,
    hasCompanyLogo: false,
    hasEmailSignature: false,
    hasKeyCustomers: false,
  },
  hasMappedGroupsToDepartments: false,
  assignTemplatesToDepartments: "no-tags",
  hasConfirmedAllDeliveryTests: false,
};

const simulationChecklistSlice = createSlice({
  name: "simulation-checklist",
  initialState,
  reducers: {
    updateChecklistState: (_, action) => {
      return {
        mailHost: action.payload.mail_host,
        hasMailPermissions: action.payload.has_mail_permissions,
        hasProvisioningPermissions: action.payload.has_provisioning_permissions,
        hasAtLeastOneNonAdminUser:
          action.payload.has_at_least_one_non_admin_user,
        deliveryTests: action.payload.delivery_tests,
        companyScan: {
          hasKeyPeople: action.payload.company_scan.has_key_people,
          hasCompanyLogo: action.payload.company_scan.has_company_logo,
          hasEmailSignature: action.payload.company_scan.has_email_signature,
          hasKeyCustomers: action.payload.company_scan.has_key_customers,
        },
        hasMappedGroupsToDepartments:
          action.payload.has_mapped_groups_to_departments,
        assignTemplatesToDepartments:
          action.payload.assign_templates_to_departments,
        hasConfirmedAllDeliveryTests:
          action.payload.has_confirmed_all_delivery_tests,
      };
    },
  },
});

export const { updateChecklistState } = simulationChecklistSlice.actions;

export const selectSetupChecklistState = (
  state: RootState,
): SetupChecklistSliceType => {
  return state.simulationChecklist;
};

export const selectStepperFromChecklistState = (
  state: RootState,
): SetupStep[] => {
  return new SetupStepper(state.simulationChecklist).steps;
};

export default simulationChecklistSlice.reducer;
