import { useBackend } from "@/utils/misc";
import React, { useEffect, useState } from "react";
import { Slack } from "@/pages/awareness/setup/Slack";
import { Teams } from "@/pages/awareness/setup/Teams/Teams";
import { PleaseActivateAwarenessPopup } from "@/pages/awareness/setup/PleaseActivateAwarenessPopup";
import { Typography } from "@mui/material";
import { Blur } from "@/utils/styled-components";
import { useUserContext } from "@/utils/contexts/UserContext";
import { Preferences } from "@/pages/awareness/setup/Preferences";
import StickyHeader from "@/components/StickyHeader";
import Tabs, {
  TabObject,
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";
import { FullSizeBigSpinner } from "@/components/Spinner";
import AwarenessSetupChecklist from "./Checklist/Setup";

const useAwarenessSetup = () => {
  const {
    data: awarenessConfig,
    error: isError,
    isPending,
  } = useBackend(`${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/company/`);
  const [tabs, setTabs] = useState<TabObject[]>([]);

  useEffect(() => {
    if (awarenessConfig == null) {
      return;
    }
    const tabs = [
      {
        label: "Setup Checklist",
        anchor: "setup-checklist",
        component: <AwarenessSetupChecklist />,
      },
      {
        label: "Preferences",
        anchor: "preferences",
        component: (
          <Preferences
            days_between_courses={awarenessConfig.days_between_courses}
          />
        ),
      },
    ];
    switch (awarenessConfig.messaging_platform) {
      case "teams":
        tabs.push({
          label: "Chatbot Configuration",
          anchor: "chatbot-configuration",
          component: <Teams />,
        });
        break;
      case "slack":
        tabs.push({
          label: "Chatbot Configuration",
          anchor: "chatbot-configuration",
          component: <Slack />,
        });
        break;
      default:
        break;
    }
    setTabs(tabs);
  }, [awarenessConfig?.days_between_courses, setTabs, awarenessConfig]);
  return {
    isError,
    isPending,
    tabs,
  };
};

export const Setup: React.FC = () => {
  const { isError, isPending, tabs } = useAwarenessSetup();

  if (isError) {
    return "Something went wrong when loading the page, please try again later.";
  }

  if (isPending) {
    return <FullSizeBigSpinner />;
  }

  return (
    <TabsContextProvider value={{ tabs: tabs }}>
      <SetupInner />
    </TabsContextProvider>
  );
};

const SetupInner: React.FC = () => {
  const { current_company } = useUserContext();
  const { currentTabComponent } = useTabsContext();

  return (
    <>
      <Blur
        blur={current_company.awareness_activated ? 0 : 5}
        onClickCapture={(e) =>
          !current_company.awareness_activated ? e.stopPropagation() : null
        }
      >
        <StickyHeader height="120px">
          <Typography variant="h2">Awareness Setup</Typography>
          <Tabs />
        </StickyHeader>
        {currentTabComponent}
      </Blur>
      {!current_company.awareness_activated ? (
        <PleaseActivateAwarenessWrapper />
      ) : null}
    </>
  );
};

const PleaseActivateAwarenessWrapper: React.FC = () => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        top: "-100px",
      }}
    >
      <PleaseActivateAwarenessPopup />
    </div>
  );
};
