import {
  SetupChecklistSliceType,
  StepperStateKey,
} from "./SetupChecklistTypes";
import { cloneDeep } from "lodash";
import { SetupStep } from "@/components/stepper/Stepper";

export class SetupStepper {
  readonly steps: SetupStep[];

  constructor(state: SetupChecklistSliceType) {
    this.steps = cloneDeep(defaultStepperState);
    this.initStepper(state);
  }

  initStepper(state: SetupChecklistSliceType) {
    const keys = Object.values(StepperStateKey).filter(
      (key) => !isNaN(Number(key)),
    );
    keys.forEach((key) => {
      stepsInitializer[key](state, this.steps[key]);
    });
  }
}

const defaultStepperState: SetupStep[] = [];
defaultStepperState[StepperStateKey.MAILING_PERMISSIONS] = {
  label: "Mailing Permissions",
  status: "uncompleted",
  mandatory: true,
};
defaultStepperState[StepperStateKey.PROVISIONING_PERMISSIONS] = {
  label: "Provisioning Permissions",
  status: "uncompleted",
  mandatory: true,
};
defaultStepperState[StepperStateKey.PROVISION_ONE_USER] = {
  label: "Provision at least one other user",
  status: "uncompleted",
  mandatory: true,
};
defaultStepperState[StepperStateKey.SIMULATION_DELIVERY_TEST] = {
  label: "Test Delivery",
  status: "uncompleted",
  mandatory: true,
};
defaultStepperState[StepperStateKey.COMPANY_SCAN] = {
  label: "Company Scan",
  status: "uncompleted",
  mandatory: false,
};
defaultStepperState[StepperStateKey.MAP_GROUPS_DEPARTMENTS] = {
  label: "Map Groups to departments",
  status: "uncompleted",
  mandatory: false,
};
defaultStepperState[StepperStateKey.ATTRIBUTE_TEMPLATES_DEPARTMENTS] = {
  label: "Attribute Saas to departments",
  status: "uncompleted",
  mandatory: true,
};

const stepsInitializer: ((
  checklistSliceState: SetupChecklistSliceType,
  currentStepState: SetupStep,
) => void)[] = [];
stepsInitializer[StepperStateKey.MAILING_PERMISSIONS] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.hasMailPermissions) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};
stepsInitializer[StepperStateKey.PROVISIONING_PERMISSIONS] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.hasProvisioningPermissions) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};
stepsInitializer[StepperStateKey.PROVISION_ONE_USER] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.hasAtLeastOneNonAdminUser) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "warning";
  }
};
stepsInitializer[StepperStateKey.SIMULATION_DELIVERY_TEST] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.hasConfirmedAllDeliveryTests) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};
stepsInitializer[StepperStateKey.COMPANY_SCAN] = (
  checklistSliceState,
  currentStepState,
) => {
  const companyScan = checklistSliceState.companyScan;
  if (
    companyScan.hasKeyPeople &&
    companyScan.hasEmailSignature &&
    companyScan.hasKeyCustomers
  ) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "warning";
  }
};
stepsInitializer[StepperStateKey.MAP_GROUPS_DEPARTMENTS] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.hasMappedGroupsToDepartments) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "warning";
  }
};
stepsInitializer[StepperStateKey.ATTRIBUTE_TEMPLATES_DEPARTMENTS] = (
  checklistSliceState,
  currentStepState,
) => {
  switch (checklistSliceState.assignTemplatesToDepartments) {
    case "no-tags":
      currentStepState.status = "error";
      break;
    case "default-tags":
      currentStepState.status = "warning";
      break;
    case "user-assigned-tags":
      currentStepState.status = "succeeded";
      break;
  }
};
