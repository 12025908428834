import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStepperFromChecklistState,
  updateChecklistState,
} from "./SetupChecklistSlice";
import axios from "axios";
import { StepperStateKey } from "@/pages/phishing-simulation/setup/SetupChecklistTypes";
import MailingPermissions from "@/pages/phishing-simulation/setup/stepComponents/MailingPermissions";
import ProvisioningPermissions from "@/pages/phishing-simulation/setup/stepComponents/ProvisioningPermissions";
import ProvisionAtLeastOneOtherUser from "@/pages/phishing-simulation/setup/stepComponents/ProvisionAtLeastOneOtherUser";
import TestDelivery from "@/pages/phishing-simulation/setup/stepComponents/TestDelivery";
import CompanyScan from "@/pages/phishing-simulation/setup/stepComponents/CompanyScan";
import MapGroupsToDepartments from "@/pages/phishing-simulation/setup/stepComponents/MapGroupsToDepartments";
import AttributeTemplatesToDepartments from "@/pages/phishing-simulation/setup/stepComponents/AttributeTemplatesToDepartments";
import { Box } from "@mui/material";
import { CenteredBigSpinner } from "@/components/Spinner";
import {
  SetupChecklist,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";

const usePhishingSimulationSetup = () => {
  const dispatch = useDispatch();
  const steps = useSelector(selectStepperFromChecklistState);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // TODO when we will have an interactive setup checklist / wizard,
  // we may want to move this logic in a Redux/rtk-query slice
  // (and do the rest of the logic also with rtk-query)
  useEffect(
    function fetchSetupStatus() {
      axios
        .get(
          `${
            import.meta.env.VITE_APP_ENDPOINT_SIMULATION
          }/company/setup-status`,
        )
        .then((response) => {
          dispatch(updateChecklistState(response.data));
          setIsLoading(false);
        })
        .catch(() => {
          setIsError(true);
        });
    },
    [dispatch, setIsLoading, setIsError],
  );

  return {
    steps,
    isLoading,
    isError,
  };
};

const SimulationCurrentComponent: React.FC = () => {
  const checklist = useContext(SetupChecklistContext);

  switch (checklist.activeIndex) {
    case StepperStateKey.MAILING_PERMISSIONS:
      return <MailingPermissions />;
    case StepperStateKey.PROVISIONING_PERMISSIONS:
      return <ProvisioningPermissions />;
    case StepperStateKey.PROVISION_ONE_USER:
      return <ProvisionAtLeastOneOtherUser />;
    case StepperStateKey.SIMULATION_DELIVERY_TEST:
      return <TestDelivery />;
    case StepperStateKey.COMPANY_SCAN:
      return <CompanyScan />;
    case StepperStateKey.MAP_GROUPS_DEPARTMENTS:
      return <MapGroupsToDepartments />;
    case StepperStateKey.ATTRIBUTE_TEMPLATES_DEPARTMENTS:
      return <AttributeTemplatesToDepartments />;
  }
};

export const SimulationSetupChecklist: React.FC = () => {
  const { steps, isLoading, isError } = usePhishingSimulationSetup();

  return (
    <>
      {isLoading && !isError ? (
        <Box marginTop="5rem">
          <CenteredBigSpinner />
        </Box>
      ) : (
        <SetupChecklist steps={steps}>
          <SimulationCurrentComponent />
        </SetupChecklist>
      )}
      {isError ? "an error occurred please try again later" : null}
    </>
  );
};
