export type TemplateAssignationStatus =
  | "no-tags"
  | "default-tags"
  | "user-assigned-tags";

export enum MailHost {
  OFFICE = 0,
  GMAIL = 1,
}

export type SetupChecklistSliceType = {
  // isMantraStaff: boolean,
  mailHost: MailHost;
  hasMailPermissions: boolean;
  // provisioning: "OFFICE"|"GMAIL"|"OKTA"|"CSV",
  hasProvisioningPermissions: boolean;
  hasAtLeastOneNonAdminUser: boolean;
  deliveryTests: {}; // same state as in the setup phising-simulation,
  companyScan: {
    hasKeyPeople: boolean;
    hasCompanyLogo: boolean;
    hasEmailSignature: boolean;
    hasKeyCustomers: boolean;
  };
  hasMappedGroupsToDepartments: boolean; // same as map groups to departments page
  assignTemplatesToDepartments: TemplateAssignationStatus;
  hasConfirmedAllDeliveryTests: boolean;
};

export enum StepperStateKey {
  MAILING_PERMISSIONS = 0,
  PROVISIONING_PERMISSIONS = 1,
  PROVISION_ONE_USER = 2,
  SIMULATION_DELIVERY_TEST = 3,
  COMPANY_SCAN = 4,
  MAP_GROUPS_DEPARTMENTS = 5,
  ATTRIBUTE_TEMPLATES_DEPARTMENTS = 6,
}
