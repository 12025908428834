import { configureStore } from "@reduxjs/toolkit";
import CourseSharingReducer from "@/pages/awareness/courses/corporate-course-sharing/courseSharingSlice";
import CourseReducer from "@/pages/awareness/courses/course-editor/courseSlice";
import CourseCatalogReducer from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import PermissionsReducer from "@/pages/general-settings/permissions-attribution/permissionsSlice";
import AuthReducer from "@/utils/contexts/AuthSlice";
import TemplateReducer from "@/pages/templates/templateSlice";
import SimulationChecklistReducer from "@/pages/phishing-simulation/setup/SetupChecklistSlice";
import AwarenessChecklistReducer from "@/pages/awareness/setup/Checklist/SetupChecklistSlice";
import AwarenessUsersSettingsReducer from "@/pages/awareness/userSlice";
import { courseEditorApi } from "@/api";
import { permissionsApi } from "@/pages/general-settings/permissions-attribution/remoteApis";
import { templateApi } from "@/pages/templates/templateApi";
import { courseSharingApi } from "@/pages/awareness/courses/corporate-course-sharing/courseSharingApi";
import { awarenessUsersSettingsApi } from "@/pages/awareness/userApi";

const store = configureStore({
  reducer: {
    courseEditor: CourseReducer,
    courseCatalog: CourseCatalogReducer,
    courseSharing: CourseSharingReducer,
    template: TemplateReducer,
    auth: AuthReducer,
    usersWithPermissions: PermissionsReducer,
    simulationChecklist: SimulationChecklistReducer,
    awarenessChecklist: AwarenessChecklistReducer,
    awarenessUser: AwarenessUsersSettingsReducer,
    [courseEditorApi.reducerPath]: courseEditorApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
    [awarenessUsersSettingsApi.reducerPath]: awarenessUsersSettingsApi.reducer,
    [courseSharingApi.reducerPath]: courseSharingApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      courseEditorApi.middleware,
      permissionsApi.middleware,
      templateApi.middleware,
      awarenessUsersSettingsApi.middleware,
      courseSharingApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
