import React, { useContext } from "react";
import { DeploymentTest } from "@/pages/banners/setup/DeploymentTest";
import { SampleEmails } from "@/pages/banners/setup/SampleEmails";
import { HistoricalAnalysis } from "@/pages/banners/setup/HistoricalAnalysis";
import {
  SetupChecklist,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";
import { StepperStateKey } from "@/pages/banners/setup/BannersChecklistTypes";
import { SetupStep } from "@/components/stepper/Stepper";

const BannerCurrentComponent: React.FC = () => {
  const checklist = useContext(SetupChecklistContext);

  switch (checklist.activeIndex) {
    case StepperStateKey.DEPLOYMENT_TEST:
      return <DeploymentTest />;
    case StepperStateKey.SAMPLE_EMAILS:
      return <SampleEmails />;
    case StepperStateKey.HISTORICAL_ANALYSIS:
      return <HistoricalAnalysis />;
  }
};

export const BannersSetupChecklist: React.FC = () => {
  const steps: SetupStep[] = [
    { label: "Deployment Test", status: "uncompleted", mandatory: true },
    { label: "Sample Emails", status: "uncompleted", mandatory: false },
    { label: "Historical Analysis", status: "uncompleted", mandatory: true },
  ];

  return (
    <SetupChecklist steps={steps}>
      <BannerCurrentComponent />
    </SetupChecklist>
  );
};
