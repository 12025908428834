import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  setListAwarenessUserSettings,
  updateUseWebchat,
} from "@/pages/awareness/userSlice";

export const awarenessUsersSettingsApi = createApi({
  reducerPath: "awarenessUsersSettingsApi",
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchAwarenessUsers: builder.query({
      query: (queryData) => ({
        url: `company/${queryData.company_id}/users`,
        method: "GET",
        params: {
          page: queryData.page,
          page_size: queryData.page_size,
          search_value: queryData.search_value,
          department_id: queryData.department_id,
        },
      }),
      onQueryStarted: async (queryData, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(setListAwarenessUserSettings(data));
      },
    }),
    updateUseWebchat: builder.mutation({
      query: ({ users_id, use_webchat }) => ({
        url: `users/update_use_webchat`,
        method: "POST",
        body: {
          users_id: users_id,
          use_webchat: use_webchat,
        },
      }),
      onQueryStarted: async (queryData, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          updateUseWebchat({
            users_id: queryData.users_id,
            use_webchat: queryData.use_webchat,
          }),
        );
      },
    }),
  }),
});

export const { useFetchAwarenessUsersQuery, useUpdateUseWebchatMutation } =
  awarenessUsersSettingsApi;
