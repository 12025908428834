import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";

export enum SimulationAccess {
  NO_ACCESS = 0,
  TRIAL_MODE = 1,
  FULL_ACCESS = 2,
}

type UserContextCompanyType = {
  id: number | null;
  name: string;
  mailHost: "OFFICE" | "GMAIL";
  provider: string;
  awareness_activated: boolean;
  banners_activated: boolean;
  simulation_access: SimulationAccess;
  domain: string;
};

export type UserContextType = {
  email: string;
  firstname?: string;
  lastname?: string;
  is_impersonate?: boolean;
  is_admin: boolean;
  is_corporate_admin: boolean;
  base_company?: UserContextCompanyType;
  permissions?: Permission[];
  companies?: {
    id: number;
    name: string;
  }[];
  current_company?: UserContextCompanyType;
  is_authenticated: boolean;
  language: string;
  uses_formal_tone: boolean;
};
