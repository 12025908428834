import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useBannerUsers from "@/pages/banners/remote-apis/bannerUsers";
import styled from "@emotion/styled";
import {
  useAnalyzeHistoricalMessages,
  useDownloadReport,
  useHistoricalReportList,
} from "@/pages/banners/remote-apis/bannerHistoricalAnalysis";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import dayjs from "dayjs";

const HISTORY_DELAY_MILLISECONDS = 1000 * 60 * 60 * 24 * 7; // one week

const StyledAutocomplete = styled(Autocomplete)(
  () =>
    `
    & .MuiChip-root {
        width: initial;
        fontWeight: 500;
      }
    `,
);

export function HistoricalAnalysis() {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const {
    users,
    usersFetchingError: usersError,
    usersFetchIsPending: usersPending,
  } = useBannerUsers(false);

  const { isSending, analyzeHistoricalMessage } =
    useAnalyzeHistoricalMessages();

  const [buttonActivated, setButtonActivated] = useState(false);

  useEffect(() => {
    setButtonActivated(
      !isSending && usersError === null && selectedUsers.length !== 0,
    );
  }, [isSending, usersError, selectedUsers]);

  useEffect(() => {});

  const handleAnalyze = async (e) => {
    e.preventDefault();
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - HISTORY_DELAY_MILLISECONDS);
    const maxEmails = Math.round(1000 / selectedUsers.length);
    await analyzeHistoricalMessage({
      userEmails: selectedUsers,
      startDate: startDate,
      endDate: endDate,
      maxEmails: maxEmails,
    });
  };

  return (
    <>
      <form onSubmit={handleAnalyze}>
        <div>
          Select a subset of users and discover what senders would have
          triggered the most banners on a sample of 1000 emails they received
          the last week. Click <em>Analyze</em> to generate an Excel report.
        </div>
        <div className="mt-4 mb-4">
          {usersPending ? (
            <span>Loading the user list...</span>
          ) : usersError ? (
            <span>Unable to load the user list...</span>
          ) : (
            <Tags
              options={users.map((u) => u.email)}
              label={"Users"}
              selected={selectedUsers}
              onChange={(_, newValue) => setSelectedUsers(newValue)}
            />
          )}
        </div>
        <div>
          <Button variant="contained" type="submit" disabled={!buttonActivated}>
            Analyze
          </Button>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <em>Analyses may take several minutes to complete.</em>
        </div>
        <div>
          <Reports />
        </div>
      </form>
    </>
  );
}

export default function Tags({ options, label, onChange, selected }) {
  return (
    <StyledAutocomplete
      multiple
      options={options}
      getOptionLabel={(option: string) => option}
      filterSelectedOptions
      onChange={onChange}
      value={selected}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}

function DownloadButton({ filename, url }) {
  const { downloadReport } = useDownloadReport();

  const initiateDownload = ({ contentType, data }) => {
    let element = document.createElement("a");
    element.setAttribute("href", `data:${contentType};base64,${data}`);
    element.setAttribute("download", filename);
    element.click();
    element.remove();
  };

  const handleDownload = async () => {
    const { contentType, data } = await downloadReport(url);
    if (contentType && data) {
      initiateDownload({ contentType, data });
    }
  };

  return (
    <>
      <Button variant="text" size="small" onClick={handleDownload}>
        <DownloadIcon />
      </Button>
    </>
  );
}

function Reports() {
  const { isPending, error, reports } = useHistoricalReportList();

  const headers = [
    { label: "", alignment: "left" as const },
    { label: "File name", alignment: "left" as const },
    { label: "Date", alignment: "left" as const },
    { label: "Users", alignment: "left" as const },
  ];

  const reports2Rows = (reports) => {
    return reports.map((row, index) => {
      return (
        <TableRow
          key={index}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell align={headers[0].alignment} width="10px">
            <DownloadButton
              filename={`banners_analysis-${dayjs(row.date).format(
                "YYYY-MM-DD_HHmm",
              )}.xlsx`}
              url={row.url}
            />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            align={headers[1].alignment}
            width="300px"
          >
            {`banners_analysis-${dayjs(row.date).format(
              "YYYY-MM-DD_HHmm",
            )}.xlsx`}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            align={headers[1].alignment}
            width="250px"
          >
            {new Date(row.date).toLocaleString()}
          </TableCell>
          <TableCell align={headers[2].alignment}>
            {row.email_addresses.join(", ")}
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {error ? (
        <div>An error occurred</div>
      ) : isPending ? (
        <div>Loading...</div>
      ) : (
        <ReportTable
          headers={headers}
          rows={reports2Rows(
            reports.sort((a, b) =>
              a.date < b.date ? 1 : a.date > b.date ? -1 : 0,
            ),
          )}
        />
      )}
    </div>
  );
}

function ReportTable({ headers, rows }) {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell align={header.alignment} key={header.label}>
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
